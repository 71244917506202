// import * as R from 'ramda'
import store from '@/store/index.js'
// import { isValid, format } from "date-fns"
import { required, minLength, maxLength, numeric, email } from "vuelidate/lib/validators"


export default class Product {
   constructor (data = {}) {
    this.id                  = data.id ? data.id : ""
    this.attributes          = data.attributes ? data.attributes : ""
    this.base_image          = data.base_image ? data.base_image : ""
    this.description         = data.description ? data.description : ""
    this.short_description   = data.short_description ? data.short_description : ""
    this.super_attributes    = data.super_attributes ? data.super_attributes : ""
    this.name                = data.name ? data.name : ""
    this.url_key             = data.url_key ? data.url_key : ""
    this.price               = data.price ? data.price : ""
    this.formated_price      = data.formated_price ? data.formated_price : ""
    this.images              = data.images ? data.images : ""
    this.primaryImage        = data.base_image ? data.base_image : ""
    this.in_stock            = data.in_stock ? data.in_stock : ""
    this.sku                 = data.sku ? data.sku : ""
    this.tax                 = data.tax ? data.tax : ""
    this.type                = data.type ? data.type : ""
    this.slug                = data.slug ? data.slug : ""
    this.variants            = data.variants ? data.variants : ""
  }


  async preloadImages() {
    for (var imageSize in this.primaryImage) {
      this.preloadImage(this.primaryImage[imageSize])
    }


    for (var imageSize in this.images) {
      this.preloadImage(this.images[imageSize].large_image_url)
      this.preloadImage(this.images[imageSize].medium_image_url)
      this.preloadImage(this.images[imageSize].small_image_url)
    }

  }

  async preloadImage(src) {
    let img = new Image();
    img.src = src;
  }


}
