<template>
  <div class="glider" ref="glider">
    <div v-for="(product,i) in products" :key="i">
      <router-link tag="div" align="center" justify="center" :to="'/product/' + product.url_key" class="product">
        <img :src="product.base_image.optimized.medium" @error="product.base_image.optimized.medium = product.base_image.medium_image_url" :alt="product.name + ' poster'" style="width:100%;" :ref="product.url_key" ></img>
        {{product.name}}
      </router-link>
    </div>
  </div>
</template>

<script>
import Glider from 'glider-js';
import 'glider-js/glider.min.css'

export default {
  name: 'ProductSlider',
  components: {

  },
  props: ['products'],
  data: () => ({

  }),
  async mounted () {
    this.initGlider()
  },
  methods: {
    initGlider() {

      let glider = new Glider(this.$refs['glider'], {
        slidesToShow: 3.5,
        slidesToScroll: 3,
        draggable: true,
        // dots: '.dots',
        // arrows: {
        //   prev: '.glider-prev',
        //   next: '.glider-next'
        // },
        responsive: [
          {
            // screens greater than >= 300px
            breakpoint: 300,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
              itemWidth: 150,
              duration: 0.5
            }
          },
          {
            // screens greater than >= 775px
            breakpoint: 775,
            settings: {
              // Set to `auto` and provide item width to adjust to viewport
              slidesToShow: 3.5,
              slidesToScroll: 'auto',
              itemWidth: 150,
              duration: 0.25
            }
          },
          {
            // screens greater than >= 1024px
            breakpoint: 1024,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 1,
              itemWidth: 150,
              duration: 0.25
            }
          }
        ]
      })



    },
  }
};
</script>

<style lang="scss">
/* .v-skeleton-loader__image {
  height: 230px!important;
  border-radius: 0;
} */

  $skeleton-loader-image-height: 300px;
</style>
